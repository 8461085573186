@import "./../../../constants";

.masterjet-nozzle-variant--header {
  font-weight: bold;
  padding: 16px;
  padding-bottom: 0px;
  background: $white;
  color: $secondary2
}

.masterjet-nozzle-variant {
  background: $white;
  padding-bottom: 16px;
}

.masterjet-no-calculation {
  border-top: 0.5px solid $secondary2;
}

.masterjet_product_selection_image-container {
  display: none;
}

.masterjet_nozzle_selection_desktop {
  display: none;
}

.masterjet_label_mobile {
  display: block;
}

.masterjet_label_desktop {
  display: none;
}

@media #{$mobile-min} {

  .masterjet_result {
    display: flex;
    flex-direction: column;
  }

  .masterjet_nozzle_configuration {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0px;
    flex-wrap: wrap;
  }

  .masterjet_label_mobile {
    display: none;
  }
  .masterjet_label_desktop {
    display: block;
  }

  .masterjet_nozzle_selection_desktop_image {
    padding: 30px;
    width: 480px;
    background: $secondary4;
    border: 1px solid $secondary1;
    margin: 30px 15px;
  }

  .masterjet_nozzle_selection_mobile {
    display: none;
  }

  .masterjet_nozzle_selection_desktop {
    display: block;
  }

  .masterjet_product_selection {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 15px;

    .masterjet_product_selection-inner {
      padding: 30px;
      background: $secondary4;
      border: 1px solid $secondary1;
      width: 480px;
      display: flex;
      align-items: center;

      .masterjet_product_selection_content-container {
        flex: 1;

        .option-select {
          background-color: transparent;
          padding: 0px;
          padding-bottom: 8px;
        }

        .option-select-item {
          background-color: transparent;
        }

        .option-select-item-active {
          background-color: $secondary2;
        }
      }

      .masterjet_product_selection_image-container {
        display: block;
        margin-right: 32px;
      }

      .masterjet_product_selection_image-container {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .masterjet_product_selection_image-dummy,
      .masterjet_product_selection_image-container img {
        display: block;
        width: 100px;
      }
    }

  }

  .masterjet_configuration_nozzle-slider-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

}

@media (min-width : 1430px) {
  .masterjet_result {
    display: flex;
    flex-direction: row;

    .masterjet_nozzle_selection_desktop {
      display: block;
      margin-right: 32px;
    }
  }
}
