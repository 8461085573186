@import "./../../../constants";

.configuration-table {
  width: 100%;
  table-layout:fixed;
}

.configuration-table-item-row {
  height: 50px;
  border-bottom: 1px solid $secondary1;
  background: $secondary5;
  font-size: $fontsize1;
  font-weight: bold;
  td {
    color: $secondary2;
    text-align: center;
  }
}

.configuration-table-heading-row {
  background: $secondary5;
  height: 27px;
  border-bottom: 1px solid $secondary1;
  font-size: $fontsize1;
  font-weight: bold;

  th {
    color: $secondary8;
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
  }
  .configuration-table-heading-delete {
    width: 50px;
  }
}

.configuration-table-add-row {
  text-align: center;
  height: 50px;
  background: $white;
  border-bottom: 1px solid $secondary1;
  cursor: pointer;
  td {
    padding-top: 5px;
    text-align: center;
    color: $secondary1;
  }
}
.configuration-table-add-row:hover{
  background: $secondary4;
}

.configuration-table-item-row-delete {
  text-align: right;
  width: 40px;
  > svg {
    cursor: pointer;
  }
}
.configuration-table-item-row-delete-icon {
  height: 40px;
  width: 40px;
}
.configuration-table-item-row-image,
.configuration-table-item-row-col-heading {
  display: none;
}
@media #{$mobile-min} {
  .configuration-table {
    padding: 15px 0px;

    display: block;
    > thead {
      display: none;
    }
    > tbody {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      > tr {
        margin: 15px;
        padding: 30px;
        background: $secondary4;
        border: 1px solid $secondary1;

        width: 480px;
        display: block;
        height: auto;
        position: relative;

        > td {
          display: block;
          width: calc(100% - 130px);
          float: left;
          text-align: left;
          min-height: 45px;
          border-top: 1px solid $secondary2;
          padding: 13px;
          margin-left: 130px;

          &:nth-child(1),
          &:nth-child(2){
            border-top: 0px;
          }

          &.configuration-table-item-row-image {
            padding: 0px;
            border-top: 0px;
            margin-left: 13px;
            width: 100px;
            height: auto;
            top:13px;
            bottom: 13px;
            left:13px;
            position: absolute;

            &.clickable{
              cursor: pointer;
            }

            > img {
              position: absolute;
              width: 100px;
              height: 100px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          > .configuration-table-item-row-col-heading {
            display: inline-block;
            font-weight: bold;
            width: 130px;
            font-size: $fontsize1;
            color: $black;
          }

          > .configuration-table-item-row-col-content {
            display: inline-block;
            text-align: center;
            width: calc(100% - 130px);
            cursor: pointer;
            font-size: $fontsize1;
            color: $secondary2;
          }

          &.configuration-table-item-row-delete {
            position: absolute;
            top: 0px;
            border-top: none;
            left: -130px;
            width: 26px;
            height: 26px;
            background-color: $secondary3;
            display: none;

            &.active{
              display: block;
            }

            .configuration-table-item-row-delete-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              height: 20px;
              width: 20px;
              transform: translateX(-50%) translateY(-50%);
            }
          }

          &.configuration-table-item-row-delete:hover{
            background-color: $secondary5;
          }
        }

      }
    }
  }
  .configuration-table-add-row {
    background: none !important;
    border: 0px !important;
    min-height: 200px;

    > td {
      margin: 0px !important;
    }
  }

  .configuration-table-add-row-label {
    display: inline-block;
    position: absolute;
    left: 200px;
    top: 100px;
    color: $black;
    font-weight: bold;
  }
  .configuration-table-plus-icon {
    display: inline-block;
    svg {
      width: 70px;
      height: 70px;
    }

    padding: 30px;
    background: $secondary4;
    border: 1px solid $secondary1;
  }
  .configuration-table-plus-icon:hover{
    background: $secondary7;
  }

  .empty-image-value{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 20px;
  }
}
