@import "./../../../constants";

.circular-slider-container{

  font-size: 280px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

  .circular-slider {
    display: block;
    cursor: pointer;
    fill: transparent;
    width: 100%;
    max-width: 400px;
    height: auto;
    max-height: 398px;
    margin-left: auto;
    margin-right: auto;
  }
    .circular-slider-circle {
      fill: transparent;
    }
    .circular-slider-bar-steps{
      stroke: $secondary6;
    }
    .circular-slider-bar-steps-overlay{
      stroke: $secondary3;
    }
    .circular-slider-bar {
      stroke: $secondary6;
    }
    .circular-slider-progress {
      stroke: $secondary1;
    }
    .circular-slider-picker{
      fill: $white;
      stroke: $black;
      stroke-width: 0.3;
    }
    .circular-slider-step-lable{
      font-size: 6px;
      fill: $black;
    }
}
