@import "./../../../constants";

.tankcleaningunit-result-rpm-header {
  font-size: $fontsize2;
  text-align: center;
  padding: 20px 0px 10px 0px;
}

.tankcleaningunit-result-rpm {

  display: flex;
  margin: 0 15px;
  overflow: hidden;
  background: $green;

  .tankcleaningunit-result-rpm-min {
    font-size: $fontsize3;
    font-weight: bold;
    flex:1;
    text-align: center;
    padding: 15px;
  }
  .tankcleaningunit-result-rpm-min-extended{
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .tankcleaningunit-result-rpm-arrow {
    min-width: 80px;
    padding: 15px;
    flex: 5;
    position: relative;
  }
  .tankcleaningunit-result-rpm-max {
    font-size: $fontsize3;
    font-weight: bold;
    flex:1;
    text-align: center;
    padding: 15px;
  }
  .tankcleaningunit-result-rpm-max-extended{
    width: calc(50%);
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
  }

  &.tankcleaningunit-result-rpm-valid {
    .tankcleaningunit-result-rpm-max-error,
    .tankcleaningunit-result-rpm-min-error {
      background: $cerror;
      color: $white;
    }
    .tankcleaningunit-result-rpm-max-warning,
    .tankcleaningunit-result-rpm-min-warning {
      background: $cwarning;
      color: $black;
    }
  }

  &.tankcleaningunit-result-rpm-error {
    background: $cerror;
    color: $white;
  }
  &.tankcleaningunit-result-rpm-warning {
    background: $cwarning;
    color: $black;
  }

}

.tankcleaningunit-result-rpm-unit-lable{
  font-weight: bold;
  font-size: $fontsize1;
  text-align: center;
  margin-top: -22px;
  padding-bottom: 2px;
  z-index: 1;
  position: relative;
}

.tankcleaningunit-result-rpm-unit {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-weight: bold;
  font-size: $fontsize1;
  margin: 16px;
  margin-top: 5px;
}

.tankcleaningunit-result-rpm-unit-dark {
  background: $secondary2;
  color: white;
  margin-bottom: 30px;
}

.tankcleaningunit-result-lable-container{
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.tankcleaningunit-result-lable-container-flex{
  flex: 5;
  font-size: 19px;
  font-weight: 400;
  display: flex;
  margin-bottom: -3px;
}

.tankcleaningunit-result-subtitle-container{
  display: relative;
  align-self: flex-end;
  flex:1;

}

.tankcleaningunit-result-rpm-min-lable,
.tankcleaningunit-result-rpm-max-lable{
  font-size: 14px;
  word-break: keep-all;
  white-space:nowrap;
}

.tankcleaningunit-result-icon-container{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tankcleaningunit-result-icon{
    width: 42px;
  }
}

.tankcleaningunit-result-rpm-arrow {
  text-align: center;
  padding: 0px 0px 0px 0px;
  font-size: 55px;
  line-height: 55px;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #333333;
  height: 88px;

  .tankcleaningunit-result-rpm-arrow-line{
    background-color: #333333;
    height: 5px;
    margin-left: -32px;
    margin-right: -32px;
    flex:1;
    z-index: 1;
  }

  .tankcleaningunit-result-rpm-arrow-icon{
    margin-top: 0px;
    z-index: 1;
    &.left{
      margin-left: -18px;
    }
    &.right{
      margin-right: -18px;
    }
  }
}

@media #{$mobile-min} {

  .tankcleaningunit-result-rpm-unit-lable{
    max-width: 510px;
  }

  .tankcleaningunit-result-rpm-unit,
  .tankcleaningunit-result-rpm-header,
  .tankcleaningunit-result-rpm{
    max-width: 480px;
  }
}
