@import "./../../constants";

@keyframes toastAmin {
  0%   {top: -60px}
  10%  {top: calc(env(safe-area-inset-top) + 90px)}
  90%  {top: calc(env(safe-area-inset-top) + 90px)}
  100% {top: -60px}
}


.page-container{

  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 64px !important;
  padding-bottom: env(safe-area-inset-bottom);

  .layout-info-message {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(env(safe-area-inset-top) + 90px);
    padding: 12px;
    min-width: 240px;
    max-width: 240px;
    width: 240px;
    border-radius: 8px;
    text-align: center;
    border: 2px solid $primary1;
    background: $secondary7;
    animation-name: toastAmin;
    animation-duration: 6s;
    animation-fill-mode: forwards;
  }

  .splash-bg{
    background-color: $primary1;
  }

  &.cordova{

    *{
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input{
      -webkit-user-select: auto !important;
      -khtml-user-select: auto !important;
      -moz-user-select: auto !important;
      -ms-user-select: auto !important;
      user-select: auto !important;
    }

    *{
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }

    html, body{
      overflow-x: hidden;
    }
  }

  .content{
    padding-top: 0px;
    background-color: $secondary5;
    position: relative;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: calc(80px + env(safe-area-inset-bottom));
  }

  .page-footer{
    display: none;
  }

  .sidemenu-container{
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  &.ios-statusbar{
    padding-top: calc(64px + env(safe-area-inset-top)) !important;
  }

  @media #{$mobile-min} {

    padding-top: 71px !important;
    padding-bottom: 50px !important;
    &.ios-statusbar{
      padding-top: 93px !important;
    }

    .sidemenu-container{
      display: none;
    }

    .page-footer{
      display: block;
    }

  }

}
