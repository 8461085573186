@import "./../../../constants";

.news {
   background-color: $white;
   padding: 5px 0px;

   .news-item {
      margin: 20px;
      padding: 15px;
      background: $secondary3;
      display: block;
   }

   .news-item-title {
      color: $black;
      font-weight: bold;
      margin-bottom: 10px;
   }

   .news-item-summary {
      color: $black;
   }

   .news-item-date {
      float: right;
      color: $black;
      font-weight: bold;
   }

}
