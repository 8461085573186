@import "./../../../constants";

.product-wizard-visible-desktop {
  display: none;
}

.product-wizard-visible-mobile {
  display: block;
}

.product-wizard-masterjet-item {
  width: 100%;
  padding: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $secondary2;
  cursor: pointer;

  &.clickable {
    cursor: pointer;
    font-weight: bold;
  }
}

.product-wizard-masterjet-item-image {
  margin-right: 16px;
}

.product-wizard-masterjet-item-label {
  flex: 1;
}

.product-wizard-masterjet-table-modal-item-label {
  width: 200px;
  text-align: center;
}

.product-wizard-masterjet-modal-item-section-row-label {
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  max-height: 48px;
}

.product-wizard-masterjet-modal-item-section-empty {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary1;
}

.product-wizard-masterjet-modal-item-section-row-icon {
  font-size: 50px;
  left: 0px;
  height: 48px;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: 16px;
  border-right: 1px solid $white;
  cursor: pointer;

  svg {
    vertical-align: top;
  }
}

.product-wizard-masterjet-modal-item-section-row {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

//---- modal -----

.product-wizard-masterjet-modal-scroller{
    margin: 0px 0px 64px 0px;
    overflow-y: auto;
    min-width: unset;
    min-width: 255px;
    width: 95%;
    max-width: 500px;
    max-height: calc(95% - 64px);

    &.product-wizard-masterjet-modal-ios-status-bar{
      top: calc(50% + env(safe-area-inset-top));
      max-height: calc(95% - 64px - env(safe-area-inset-bottom));
    }
}

.product-wizard-masterjet-modal-item-container {
  display: block;
}

.product-wizard-masterjet-table-modal-item {
  font-size: $fontsize1;
  text-align: left;
  cursor: pointer;
  display: block;
  min-height: 70px;
  padding: 15px;
  align-items: center;
  white-space: nowrap;
  border-top: 1px solid $secondary1;

  &.selected{
    font-weight: bold;
  }

  .product-wizard-masterjet-table-modal-item-name {
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    max-width: calc(100% - 80px);
  }
  .product-wizard-masterjet-table-modal-item-image {
    display: inline-block;
    padding: 0px 15px 0px 15px;
    img {
      width: 50px;
    }
    vertical-align: middle;
  }
}

.product-wizard-masterjet-table-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.product-wizard-masterjet-table-modal-item-custom {
  height: 70px;
  font-size: $fontsize2;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-wizard-masterjet-table-modal-item-custom:hover,
.product-wizard-masterjet-table-modal-item:hover{
  background-color: $secondary6;
}

.product-wizard-masterjet-table-modal-custom-type-container {
  margin: 10px;
  text-align: center;

  .data-unit-textfield {
    width: auto;
  }
}

.product-wizard-masterjet-table-modal-custom-type-input {
  margin-bottom: 10px;
  background: transparent;
  border: 0px;
  text-align: center;
  font-size: $fontsize3;
}

.product-wizard-masterjet-modal-item-container-info {
  position: relative;
}

.product-wizard-masterjet-modal-item-container-info-item {
  margin-left: 40px;
  margin-right: 40px;
}

.product-wizard-masterjet-modal-item-container-info-svg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.product-wizard-masterjet-modal-item-image-small,
.product-wizard-masterjet-modal-item-image {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $secondary2;

  img {
    height: 68px;
  }

  &.product-wizard-masterjet-modal-item-image--border-false {
    border-bottom: none;
  }

  &.product-wizard-masterjet-modal-item-image-fullwidth img {
    width: 100%;
    height: auto;
  }
}

.product-wizard-masterjet-modal-item-image-small {
  padding: 8px 24px;
  img {
    height: 20px;
  }
  border-bottom: none;
}


.product-wizard-masterjet-modal-item-image-large {
  padding: 0px 16px;
  img {
    max-height: 68px;
    max-width: 80px;
  }
  border-bottom: none;
}

@media #{$mobile-min} {
  .product-wizard-visible-desktop {
    display: block;
  }

  .product-wizard-visible-mobile {
    display: none;
  }
}
