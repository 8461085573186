@import "./../../../constants";

.ci-collapse-button {
  height: 80px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fontsize4;
  text-align: center;
  cursor: pointer;
  color: white;
  background-color: $secondary2;
}

.ci-collapse-button-open {
  background-color: $secondary6;
  color: $secondary2;
}
