@import "./../../../constants";

.footer{
  height: 50px;
  background-color: $secondary3;
  color: $secondary1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 100;

  .footer-navigation{
    padding: 15px;
  }

  a:link,
  a:visited{
    color: $secondary1;
    text-decoration: none;
  }

  a:active,
  a:hover{
    color: $secondary1;
    text-decoration: underline;
  }
}
