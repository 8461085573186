@import "./../../../constants";

.conversion-layout-container {
  min-height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  background-color: $secondary2;
}

.conversion-result-triangle-container {
  background: $white;
  text-align: center;
  font-size: 0px;
  border-top: 50px solid $white; //IE
  flex:1;
  position: relative;

  .conversion-result-triangle {
    position: absolute;
    bottom:-1px;
    left:50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent $secondary2 transparent;
  }
}

.conversion-element-container{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-bottom: 1px solid $secondary1;
  background-color: $secondary5;
  font-size: $fontsize4;

  .conversion-data-unit-container{
    margin-left: 50px;
  }
  .Dropdown-root {
    color: $secondary2;
    font-size: $fontsize2;
    cursor: pointer;
    padding-top: 15px;
    padding-left: 15px;
    position: relative;
  }
  .Dropdown-control {
      position: relative;
      overflow: hidden;
      background-color: $secondary5;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: default;
      outline: none;
      padding: 0px;
      transition: all 200ms ease;
  }
  .Dropdown-control:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  .Dropdown-arrow {
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content: ' ';
      display: block;
      height: 0;
      margin-top: -ceil(2.5);
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 0
  }
  .is-open .Dropdown-arrow {
      border-color: transparent transparent #999;
      border-width: 0 5px 5px;
  }
  .Dropdown-menu {
      background-color: $secondary3;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      margin-top: -1px;
      //max-height: 280px;
      overflow-y: scroll;
      position: absolute;
      top: 100%;
      //width: 150px;
      z-index: 1000;
      -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu::-webkit-scrollbar {
      -webkit-appearance: none;
  }

  .Dropdown-menu::-webkit-scrollbar:vertical {
      width: 12px;
  }

  .Dropdown-menu::-webkit-scrollbar:horizontal {
      height: 12px;
  }

  .Dropdown-menu::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 10px;
      border: 2px solid $secondary4;
  }

  .Dropdown-menu::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: $secondary4;
  }

  .Dropdown-menu .Dropdown-group>.Dropdown-title {
      padding: 8px 10px;
      color: rgba(51, 51, 51, 1.2);
      font-weight: bold;
      text-transform: capitalize;
      border-bottom: 1px solid $secondary6;
  }
  .Dropdown-option {
      box-sizing: border-box;
      color: rgba(51, 51, 51, 0.8);
      cursor: pointer;
      display: block;
      padding: 8px 10px;
  }
  .Dropdown-option:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
  }
  .Dropdown-option:hover {
      background-color: $secondary4;
      color: #333;
  }
  .Dropdown-option.is-selected {
      background-color: #f2f9fc;
      color: #333;
  }
  .Dropdown-noresults {
      box-sizing: border-box;
      color: #ccc;
      cursor: default;
      display: block;
      padding: 8px 10px;
  }
}

.conversion-dropdown-container{
  margin-top: -2px;
  width: 100px;
  height: 100%;
}

.conversion-dropdown-container-noevents{
  pointer-events: none;
}

.conversion-result-header-container{
  display: none;
}

.conversion-results-container{

  background-color: $secondary2;
  padding: 20px;
  flex:1;
  min-height: 200px; //IE

  .conversion-element-result-container{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fontsize2;
    color: $white;
    height: 40px;
    margin-left: 8px;

    .conversion-element-result-unit{
      margin-left: 3px;
      font-size: $fontsize2;
    }
  }
}

@media #{$mobile-min} {

  .conversion-layout-container{
    background-color: $secondary5;
  }

  .conversion-element-container{
    justify-content: flex-start;

    .Dropdown-root{

    }

    .conversion-dropdown-container{
      margin-top: 8px;
      .Dropdown-control{
        background-color: $secondary7;
      }
    }

    .conversion-data-unit-container{
        border: 1px solid $secondary6;
        background-color: $secondary7;
        padding: 30px;
        font-size: $fontsize5;
        font-weight: bold;
        margin: 30px 15px 30px 15px;

        justify-content: flex-start;
        min-width: 215px;
    }
  }

  .conversion-result-triangle-container{
    display: none;
  }

  .conversion-result-header-container{
    display: block;
  }

  .conversion-results-container{

    padding: 0px;
    min-height: 100px;
    background-color: $secondary5;

    .conversion-element-result-container{
      border: 1px solid $secondary6;
      background-color: $secondary7;
      color: $black;
      padding: 30px;
      font-size: $fontsize5;
      font-weight: bold;
      float: auto;
      display: inline-table;
      min-width: 215px;
      margin: 30px 15px;

      .data-unit-textfield-label{
        display: flex;
        align-items: flex-end;
        padding-left: 10px;
        padding-bottom: 9px;
      }
    }
  }

}
