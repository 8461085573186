@import "./../../../constants";

.contact-button {
  display: block;
  width: 250px;
  background: $secondary5;
  text-align: center;
  height: 40px;
  padding-top:6px;
  margin: 0 auto;
  cursor: pointer;
}
.contact-button:hover{
  background: $secondary3;
}

.contact-button-label {
  padding-left: 5px;
  display:inline-block;
  font-weight: bold;
}

.contact-button-icon {
  display:inline-block;
  font-size: $fontsize2;
}


// For multiple buttons in one line
.contact-multi-button {

  background: $secondary5;

  display: flex;
  height: 40px;
  padding: 5px;
  margin: 0 auto;
  max-width: 500px;

  .contact-button {
    flex: 1;
    flex-base: 1;
    padding: 0px;
    width: auto;
    height: 30px;
    border-right: 1px solid $secondary2;
  }
  .contact-button:last-child {
    border-right: 0px;
  }
}
