@import "./../../../constants";

.config-modal{
  cursor: pointer;
}

.nozzle-configuration-modal-scroller{
    margin: 0px 0px 64px 0px;
    overflow-y: auto;
    min-width: unset;
    min-width: 255px;
    width: 95%;
    max-width: 500px;
    max-height: calc(95% - 64px);

    &.nozzle-configuration-modal-ios-status-bar{
      top: calc(50% + 32px + 11px);
      max-height: calc(95% - 64px - env(safe-area-inset-bottom));
    }
}

.nozzle-configuration-table-modal-item-container {
  display: block;
}

.nozzle-configuration-table-modal-item {
  font-size: $fontsize1;
  text-align: left;
  cursor: pointer;
  display: block;
  min-height: 70px;
  padding: 15px;
  align-items: center;
  white-space: nowrap;

  &.selected{
    font-weight: bold;
  }

  .nozzle-configuration-table-modal-item-name {
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    max-width: calc(100% - 80px);
  }
  .nozzle-configuration-table-modal-item-image {
    display: inline-block;
    padding: 0px 15px 0px 15px;
    img {
      width: 50px;
    }
    vertical-align: middle;
  }
}

.nozzle-configuration-table-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.nozzle-configuration-table-modal-item-custom {
  height: 70px;
  font-size: $fontsize2;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nozzle-configuration-table-modal-item-custom:hover,
.nozzle-configuration-table-modal-item:hover{
  background-color: $secondary6;
}

.nozzle-configuration-table-modal-custom-type-container,
.nozzle-configuration-table-modal-custom-diameter-container {
  margin: 10px;
  text-align: center;

  .data-unit-textfield {
    width: auto;
  }
}

.nozzle-configuration-table-modal-custom-type-input,
.nozzle-configuration-table-modal-custom-diameter-input{
  margin-bottom: 10px;
  background: transparent;
  border: 0px;
  text-align: center;
  font-size: $fontsize3;
}


.nozzle-configuration-table-angle-title {
  font-weight: bold;
  font-size: $fontsize2;
  text-align: center;
}

.nozzle-configuration-table-angle-text {
  margin: 20px;
  font-size: $fontsize1;
  text-align: center;
}

@media #{$mobile-min} {
  .nozzle-configuration-modal-scroller{
    max-height: 600px;
  }
}
