$primary1: #ffed00;
$primary2: #03A9F4;
$secondary1: #878787;
$secondary2: #6b6b6b;
$secondary3: #dadada;
$secondary4: #f2f2f1;
$secondary5: #cccccc;
$secondary6: #bbbbbb;
$secondary7: #ededed;
$secondary8: #333333;
$secondary9: #a1a1a1;
$green: #95c11f;
$white: #FFFFFF;
$black: #000000;
$cnormal: #248a3c;


$colors: (
  "primary1" : #ffed00,
  "primary2": #03A9F4,
  "secondary1": #878787,
  "secondary2": #6b6b6b,
  "secondary3": #dadada,
  "secondary4": #f2f2f1,
  "secondary5": #cccccc,
  "secondary6": #bbbbbb,
  "secondary7": #ededed,
  "secondary8": #333333,
  "secondary9": #a1a1a1,
  "green" : #95c11f,
  "white" : #FFFFFF,
  "black" : #000000,
  "cwarning" : #f39200,
  "cwarning_low" : #F0DEC5,
  "cerror" : #e30613,
  "cerror_low" : #F5CED0,
  "cnormal" : #248a3c,
);



$cwarning: #f39200;
$cwarning_low: #F0DEC5;
$cerror: #e30613;
$cerror_low: #F5CED0;
$mobile-max: (max-width : 980px);
$mobile-min: (min-width : 981px);
$fontsize1: 16px;
$fontsize2: 19px;
$fontsize3: 25px;
$fontsize4: 32px;
$fontsize5: 42px;
