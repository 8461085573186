@import "./../../../constants";

.unit-selector-row {
  padding: 13px;
  height: 50px;
  border-bottom: 1px solid $secondary2;
  color: $black;
  font-size: $fontsize1;
  position: relative;
  cursor: pointer;
}
.unit-selector-row:last-child {
  border-bottom: 0px;
}
.unit-selector-row-label {
  display: inline-block;
  width: 110px;
  font-weight: bold;
  color: $black;
  vertical-align: middle;
}

.unit-selector-row-value {
  display: inline-block;
  text-align: right;
  right: 50px;
  width: calc(100% - 130px);
  height: 22px;
  white-space:nowrap;
  overflow: hidden;
  vertical-align: middle;
  color: $secondary2;
}

.unit-selector-ellipsis {
  display: inline-block;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
  width: 17px;
  height: 17px;
  color: $secondary2;
}

.unit-selector-modal {
  padding: 0px;
}

.unit-selector-modal-group,
.unit-selector-modal-row {
  background: $secondary3;
  color: $black;
  text-align: center;
  font-size: $fontsize2;
  padding: 15px;
  cursor: pointer;
  font-weight: normal;

  &.selected{
    font-weight: bold;
  }
}
.unit-selector-modal-group{
  font-weight: bold;
  cursor: default;
}

.unit-selector-modal-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.unit-selector-modal-row-no-items {
  color: $cerror;
  cursor: default;
}

.unit-selector-modal-row:hover,
.unit-selector-modal-row-no-items:hover {
  background: $secondary6;
}

.unit-selector-image {
  display: none; //mobile
}

@media #{$mobile-min} {

  .unit-selector-image-container{
    display: block; //desktop
    width: 100px;
    min-width: 100px; //IE
    height: 100px;
    flex: 0;
    margin-right: 30px;

    .unit-selector-image {
      display: block; //desktop
      width: 100px;
      height: 100px;
    }
  }
  .unit-selector {
    padding: 30px 15px;
  }

  .unit-selector-inner {
    padding: 30px;
    background: $secondary4;
    border: 1px solid $secondary1;
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unit-selector-rows {
    flex: 1;
  }

  .unit-selector-row-label {
    font-weight: bold;
  }

  .unit-selector-row-value {
    margin-right: 15px;
  }
}
