@import "./../../../constants";

.ci-buttonbar {
  display: flex;
}

.ci-buttonbar > * {
  flex-grow: 1;
  flex-basis: 50%;
  margin-right: 10px;
}

.ci-buttonbar > *:last-child {
  margin-right: 0px;
}

