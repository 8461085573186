@import "./../../../constants";

.option-select {
  display: flex;
  padding: 10px 13px;
  font-size: $fontsize1;
  background: $white;
  height: auto;

  &.option-select--border-true {
    border-bottom: 1px solid #6b6b6b;
  }

  &.option-select--dark {
    background: $secondary5;

    .option-select-item {
      background: $secondary5;
      color: $secondary1;

      &:hover {
        background: $secondary6;
      }
    }

    .option-select-item-active {
      background: $secondary2;
      color: $white;

      &:hover {
        background: $secondary2;
      }
    }
  }

  &.option-select--modal {
    background: $secondary3;

    .option-select-item {
      background: $secondary3;
      color: $secondary1;

      &:hover {
        background: $secondary6;
      }
    }

    .option-select-item-active {
      background: $secondary2;
      color: $white;

      &:hover {
        background: $secondary2;
      }
    }
  }

  &.no-elems{
    display: none;
  }

  .option-select-item-container {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 10px;
  }

  .option-select-item-container:first-child {
    margin-left:0px;
  }

  .option-select-item {
    background: $white;
    color: $secondary1;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid $secondary1;
    cursor: pointer;
  }

  .option-select--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .option-select-item-active {
    background: $secondary2;
    color: $white;
    border: 1px solid $secondary1;
  }

  .option-select-item-label {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .product-wizard-modal-label-pic-container {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100px;
    }
  }
  .product-wizard-modal-label-text-container {
    margin-top: 16px;
    font-weight: bold;
  }
}

@media #{$mobile-min} {

  .option-select--responsive-true {
    padding: 15px;

    &.no-elems{
      height: 57px;
    }

    .option-select-item {
      flex-basis: 1;
      flex-grow: 0;
    }

    .option-select-item {
      border-radius: 2px;
      font-size: $fontsize1;
    }
  }

  .masterjet-nozzle-variant {

    .option-select {
      width: 418px;
    }

    .option-select-item-container {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
