@import "./../../../constants";

.impress{
  background-color: $white;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 150px;

  h2{
    color: $black;
  }

  a:link,
  a:visited{
    color: $secondary1;
    text-decoration: none;
  }

  a:active,
  a:hover{
    color: $secondary1;
    text-decoration: underline;
  }
}
