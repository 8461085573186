@import "./../../../constants";

.tab-navigation {
  display: flex;
  padding: 10px 15px;
  font-size: $fontsize1;
  background: $white;
  height: auto;

  &.no-elems{
    display: none;
  }

  .tab-navigation-item {
    flex-basis: 0;
    flex-grow: 1;
    background: $white;
    color: $secondary1;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid $secondary1;
    margin-left: 10px;
  }

  .tab-navigation-item-active {
    background: $secondary1;
    color: $white;
    border: 1px solid $secondary1;
  }

  .tab-navigation-item:first-child {
    margin-left:0px;
  }
}

@media #{$mobile-min} {

  .tab-navigation {
    display: block;
    padding: 15px;

    &.no-elems{
      height: 57px;
      display: block;
    }
  }

  .tab-navigation-item {
    border-radius: 2px;
    font-size: $fontsize1;
  }

}
