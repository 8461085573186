@import "./../../../constants";

.aquamat-modal-item-container {
  display: block;
}

.aquamat-modal-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.aquamat-modal-item {
  font-size: $fontsize3;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;

  &.aquamat-modal-item-small{
    height: 50px;
    font-size: $fontsize2;
  }

  &.selected{
    font-weight: bold;
  }

  .aquamat-modal-item-name {
    flex: 1;

    .aquamat-modal-item-name-title{
      &.selected{
        font-weight: bold;
      }
    }
  }
  .aquamat-modal-item-image {
    width:70px;
    margin: 0px 15px 0px 15px;
    flex: 0;
    img {
      width: 50px;
    }
  }

}

.aquamat-modal-item:hover{
  background-color: $secondary6;
}

.aquamat-selector-row {
  padding: 13px;
  height: 45px;
  border-bottom: 1px solid $secondary2;
  color: $secondary2;
  font-size: $fontsize1;
  position: relative;
}
.aquamat-selector-row:last-child {
  border-bottom: 0px;
}
.aquamat-selector-row-label {
  display: inline-block;
  width: 200px;
  font-weight: bold;
  color: black;
  font-weight: $fontsize1;
}

.aquamat-selector-row-value {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 30px;
  cursor: pointer;
  color: $secondary2;
  font-weight: $fontsize1;
}

.aquamat-selector-ellipsis {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
}

.aquamat-selector-modal {
  padding: 0px;
}
.aquamat-selector-modal-row {
  background: $secondary3;
  color: $secondary2;
  text-align: center;
  font-size: $fontsize1;
  border: 1px solid $secondary2;
  padding: 15px;
  cursor: pointer;
}

.aquamat-selector-modal-row-no-items {
  color: $cerror;
  cursor: default;
}

.aquamat-modal-image {
  display: none; //mobile
}

.aquamat-modal-item-content{
  width: 100%;
}

.aquamat-modal-item-size {
  font-size: $fontsize1;
  padding: 0px 20px;

  .aquamat-modal-item-size-row {
    display: flex;
    span {
      text-align: right;
    }
    span:after {
      content:':';
    }
    > * {
      flex: 1;
    }
  }
}

.aquamat-modal-nozzle-holder-item {
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  font-size: $fontsize2;
  padding: 15px;

  &.selected{
    font-weight: bold;
  }

  .aquamat-modal-nozzle-holder-item-image {
    width:70px;
    flex: 0;
    img {
      width: 50px;
    }
    flex-basis: 70px;
  }

  .aquamat-modal-nozzle-holder-item-name{
    margin: 15px 40px 15px 15px;
    width: 100%;
    text-align: left;
  }

  &:hover {
    background-color: $secondary6;
  }
}

@media #{$mobile-min} {
  .aquamat-modal-image {
    display: block; //desktop
    width: 100px;
    min-width: 100px; //IE
    height: 100px;
    flex: 0;
    margin-right: 30px;
  }
  .aquamat-modal {
    margin: 30px 15px;
  }

  .aquamat-modal-item-inner {
    padding: 30px;
    background: $secondary4;
    border: 1px solid $secondary1;
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aquamat-modal-item {
    height: 140px;
    padding: 20px 0px;
  }
  .aquamat-modal-item-image {
    img {
      width: 100px !important;
    }
  }
}
