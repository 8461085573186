@import "./../../../constants";

.menu-icon-close{
  &.ios-statusbar{
    padding-top: env(safe-area-inset-top);
  }
}

.menu-logo-container {

  text-align: center;

  &.ios-statusbar {
    top: env(safe-area-inset-top);
  }

  .logo-menu {
    margin: 7px;
    height: 35px;
    display: inline-block;
    margin-top: 40px;
  }
}

.menu-copyright{
  position: absolute;
  bottom:10px;
  left:14px;
}

.menu-link-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.menu-spacer1 {
  height: 25px;
}

.menu-spacer2 {
  height: 40px;
}

//overwrite burger menu styles

.bm-burger-button {
  position: absolute;
  top: calc(env(safe-area-inset-top) + 4px);
  left: 14px;
  font-size: $fontsize4;
  color: $black;
  float:left;
  position: fixed;
  z-index: 200 !important;
  display: none;

  @media #{$mobile-max} {
    display: block;
  }
}

.bm-cross-button{
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: calc(env(safe-area-inset-top) - 2px) !important;
}

/* General sidebar styles */
.bm-menu {
  background: $secondary1;
  //z-index: 9999 !important;
  font-size: $fontsize1;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 14px;
  padding-top: env(safe-area-inset-top);
  height: 100%;
  color: $white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 200 !important;
}

.bm-menu-wrap {
  z-index: 300 !important;
}


.menu-item,
.menu-item:link,
.menu-item:visited {
  display: block;
  padding: 10px;
  color: $white;
  font-weight: bold;
  letter-spacing: 1px;
}

.menu-item-disclaimer,
.menu-item-disclaimer:link,
.menu-item-disclaimer:visited {
  color: $black;
  text-align: center;
  display:block;
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
  letter-spacing: 1px;
}
