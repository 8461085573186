@import "./../../../constants";

.ci-button {
  padding: 15px;
  height: 50px;
  font-size: $fontsize1;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
