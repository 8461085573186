@import "./../../../constants";

.nozzlecalculation {
  background-color: $white;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;

  h2{
    color: $primary1;
  }
}

.nozzle-tab-info {
  flex-basis: 0;
  flex-grow: 1;
  color: $black;
  margin-left: 10px;
  display: none;
}

.nozzle-tab-info-mobile {
  color: $black;
  background-color: $white;
  padding-left: 16px;
  padding-top: 10px;
  display: block;
  font-size: 16px;
}

@media #{$mobile-min} {

  .nozzle-tab-info-mobile {
    display: none;
  }
  .nozzle-tab-info{
    display: inline-block;
  }
}
