@import "./../../../constants";
@import '~loaders.css/src/animations/line-scale.scss';

.splash-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  padding: 20% 10% 20% 10%;
  display: flex;
  flex-direction: column;
}
.splash-logo {
  width: 40%;
  min-width: 200px;
  margin: 0 auto;
  flex: 1;
}

.splash-logo img {
  width: 100%;
}

.splash-title {
  font-size: 2.5em;
  margin: 5vw auto 5vw auto;
  text-align: center;
  padding-bottom: 2vw;
  border-bottom: 1px solid $black;
  flex: 1;
}

.splash-loading-indicator {
  flex: 1;
}

.loader-inner{
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}