@import "./../../../constants";

.nozzle-diameter-slider {

  background: $white;
  padding-bottom: 16px;
  padding-top: 16px;

  .nozzle-diameter-slider__header {
    background: $secondary2;
    height: 48px;
    color: $white;
    padding: 14px 15px;
    font-size: $fontsize1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .nozzle-diameter-slider__indicator {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $white;
    border: 2px solid $black;
    margin-right: 8px;

    &.nozzle-diameter-slider__indicator--invalid {
      background-color: $black;
      border-color: $white;
    }
  }

  .nozzle-diameter-slider__flex {
    flex: 1
  }

  .nozzle-diameter-slider__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: $secondary5;
  }

  .nozzle-diameter-slider__button {
    color: $white;
    background-color: $secondary2;
    padding: 16px 12px;
    margin: 4px;
    cursor: pointer;
  }

  .nozzle-diameter-slider__slider {
    @import "rc-slider/assets/index.css";

    margin: 0px 20px;

    .rc-slider-handle:after {
      content: attr(data-name);
      color: $black;
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      left: 50%;
      line-height: 30px;
      transform: translateX(-50%);
    }
  }

  .nozzle-diameter-slider__slider--invalid {
    .rc-slider-handle:after {
      color: white;
    }
  }
}

@media #{$mobile-min} {

  .nozzle-diameter-slider {
    padding: 27px 30px;
    background: $secondary4;
    border: 1px solid $secondary1;
    width: 480px;
    display: flex;
    align-items: center;
    margin: 15px;
    flex-direction: column;
    align-items: stretch;
  }


}
