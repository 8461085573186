@import "./constants";

html, body {
  height: 100vh;
  min-height: 100vh;
}

html {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


html,
body,
#root,
.page-container {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  background-color: $secondary5 !important;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

textarea:focus,
input:focus,
select:focus,
div:focus {
  outline-color: transparent;
  outline-style: none;
}

a:link,
a:visited{
  color: $primary1;
  text-decoration: none;
}

a:active,
a:hover{
  color: $primary2;
  text-decoration: none;
}

.btn-primary{
  background-color: $primary1;
  color: $white;
  border-color: $primary2;

}

.btn-primary:visited,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus{
  background-color: $primary2;
}

.btn:focus {
  outline: none;
}

input:focus {
  outline: none !important;
}

/* Modal Dialog Style */
.ci-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.4);
}

.ci-modal {
  position: absolute;
  left: 50%;
  top: calc(50% + 32px);
  min-width: 300px;
  -ms-transform: translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  background: $secondary3;
  border: 1px solid $secondary2;
  overflow: auto;
  webkit-overflow-scrolling: touch;
  outline: none;
  padding: 20px;
}

.ci-modal-nopadding {
  padding: 0px;
}

.ci-modal-confirmation-text {
  font-size: $fontsize2;
  font-weight: bold;
  margin-bottom: 20px;
}

.ci-modal-scroller {
  margin: 0px 0px 0px 0px;
  overflow-y: auto;
  min-width: unset;
  min-width: 255px;
  width: 80%;
  max-width: 400px;
  max-height: calc(100% - 200px);
}

.ci-modal-scroller-max {
  margin: 0px 0px 0px 0px;
  overflow-y: auto;
  min-width: unset;
  min-width: 255px;
  width: 95%;
  max-width: 400px;
  max-height: calc(100% - 80px);
}

.ReactModalPortal,
.ReactModal__Overlay{
  z-index: 200;
}
