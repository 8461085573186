@import "./../../../constants";

.nozzlecalculation {
  background-color: $white;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;

  h2{
    color: $primary1;
  }
}
