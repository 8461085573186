@import "./../../../constants";

.landing-container{

  padding: 20px;

  .landing-icon-coffee{
    color: $primary1;
    font-size: 16em;
  }
}


