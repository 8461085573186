@import "./../../../constants";


.calculation-result-custom-triangle-container {
  background: $white;
  text-align: center;
  padding-top: 20px;
  font-size: 0px;

  .calculation-result-custom-triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
  }

  .calculation-result-custom-triangle-valid {
    border-color: transparent transparent $secondary2 transparent;
  }

  .calculation-result-custom-triangle-warning {
    border-color: transparent transparent $cwarning transparent;
  }

  .calculation-result-custom-triangle-error {
    border-color: transparent transparent $cerror transparent;
  }
}

.calculation-result-custom-header {
  cursor: pointer;
}

.calculation-result-custom-header-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
}

.calculation-result-custom-header-container-title {
  flex: 1
}

.calculation-result-custom-header-toggle {
  font-size: $fontsize4;
}

.calculation-result-custom-values-item-valid {
  background: $secondary2;
}

.calculation-result-custom-values-item-warning {
  background: $cwarning;
}

.calculation-result-custom-values-item-error {
  background: $cerror;
}
