@import "./../../../constants";

.configuration-table-masterjet {
  width: 100%;
  table-layout:fixed;

  .desktop {
    display: none;
  }
  .mobile {
    display: table-cell;
  }
}

.configuration-table-masterjet-item-row {
  height: 50px;
  background: $secondary5;
  font-size: $fontsize1;
  font-weight: bold;
  td {
    color: $secondary2;
    text-align: center;
    border: 1px solid $secondary1;
    border-top: none;
    padding: 8px;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.configuration-table-masterjet-item-row--clickable {
  cursor: pointer;
}

.configuration-table-masterjet-heading-row {
  background: $secondary5;
  height: 27px;
  font-size: $fontsize1;
  font-weight: bold;

  th {
    color: $secondary8;
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $secondary1;

  }
  .configuration-table-heading-delete {
    width: 50px;
  }
}

.config-modal{
  cursor: pointer;
}

.nozzle-configuration-masterjet-modal-scroller{
    margin: 0px 0px 64px 0px;
    overflow-y: auto;
    min-width: unset;
    min-width: 255px;
    width: 95%;
    max-width: 500px;
    max-height: calc(95% - 64px);

    &.nozzle-configuration-masterjet-modal-ios-status-bar{
      top: calc(50% + 32px + 11px);
      max-height: calc(95% - 64px - env(safe-area-inset-bottom));
    }
}

.nozzle-configuration-masterjet-table-modal-item-container {
  display: block;
}

.nozzle-configuration-masterjet-table-modal-item {
  font-size: $fontsize1;
  text-align: left;
  cursor: pointer;
  display: block;
  min-height: 70px;
  padding: 15px;
  align-items: center;
  white-space: nowrap;
  border-top: 1px solid $secondary1;

  &.selected{
    font-weight: bold;
  }

  .nozzle-configuration-masterjet-table-modal-item-name {
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    max-width: calc(100% - 80px);
  }
  .nozzle-configuration-masterjet-table-modal-item-image {
    display: inline-block;
    padding: 0px 15px 0px 15px;
    img {
      width: 50px;
    }
    vertical-align: middle;
  }
}

.nozzle-configuration-masterjet-table-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.nozzle-configuration-masterjet-table-modal-item-custom {
  height: 70px;
  font-size: $fontsize2;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nozzle-configuration-masterjet-table-modal-item-custom:hover,
.nozzle-configuration-masterjet-table-modal-item:hover{
  background-color: $secondary6;
}

.nozzle-configuration-masterjet-table-modal-custom-type-container {
  margin: 10px;
  text-align: center;

  .data-unit-textfield {
    width: auto;
  }
}

.nozzle-configuration-masterjet-table-modal-custom-type-input {
  margin-bottom: 10px;
  background: transparent;
  border: 0px;
  text-align: center;
  font-size: $fontsize3;
}

.configuration-table-masterjet-mobile {
  display: block !important;
}
.configuration-table-masterjet-desktop {
  display: none !important;
}

@media #{$mobile-min} {

  .masterjet-nozzle-variant {
    padding: 30px;
    background: $secondary4;
    border: 1px solid $secondary1;
    width: 480px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px;

    .masterjet-nozzle-variant--header {
      background-color: transparent;
      color: $black;
    }
    .option-select-item {
      background-color: transparent;
    }
    .option-select-item-active {
      background-color: $secondary2;
    }
    .option-select {
      background-color: transparent;
      border-bottom: 0px;
    }
  }

  .configuration-table-masterjet {

    .desktop {
      display: table-cell;
    }
    .mobile {
      display: none;
    }

    padding: 25px;
    background: $secondary4;
    border: 1px solid $secondary1;
    width: 480px;
    display: flex;
    align-items: center;
    margin: 15px;

    thead{
      display: none;
    }

    tbody {
      width: 100%;
    }

    tr {
      background-color: transparent;
    }
    tr:last-of-type{
      * {
        border-bottom: none;
      }
    }

    td {
      width: 50%;
      min-width: 50px;
      padding: 0px;
    }

    td:last-of-type {
      border-right: none;
    }

    .configuration-table-masterjet-mobile {
      display: none !important;
    }
    .configuration-table-masterjet-desktop {
      display: block !important;
    }

    .configuration-table-masterjet-label {
      width: 140px;
      padding: 17px;
      border-bottom: 1px solid $secondary1;
      margin-bottom: -0.5px;
    }

    @-moz-document url-prefix() {
      .configuration-table-masterjet-label {
        margin-bottom: 0px;
      }
    }

    .configuration-table-masterjet-item-row-image {
      width: 132px;
      padding-right: 32px;
      border: none;
    }

    .configuration-table-masterjet-noz-count,
    .configuration-table-masterjet-noz-type,
    .configuration-table-masterjet-plug {
      border-left: none;
      margin-left: -1px;
    }
    .configuration-table-masterjet-plug {
      border-right: none;
    }
  }
}
