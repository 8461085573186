@import "./../../../constants";

.nozzle-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .nozzle-image-label-container {
    min-width: 288px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .nozzle-image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nozzle-image-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .nozzle-image-item-label {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nozzle-image-item-label--top {
    margin-top: 8px;
    margin-bottom: 34px;
  }

  .nozzle-image-item-label--bottom {
    margin-top: 34px;
    margin-bottom: 8px;
  }

  .nozzle-image-item-label--pc {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);

    .nozzle-image-item-label-info {
      width: auto;
      color: $black;
    }
  }

  .nozzle-image-item-label-title {
    text-align: center;
    color: $secondary2;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: $fontsize1;
    line-height: $fontsize1;
    width: 80px;
  }

  .nozzle-image-item-label-info {
    background: $secondary1;
    text-align: center;
    font-weight: bold;
    color: $white;
    font-size: 13px;
    line-height: 13px;
    width: 95px;
    padding: 3px;
  }

  .nozzle-image-item-label-info--type-green {
    background-color: $white;
    color: $black;
    border: 2px solid $black;
  }

  .nozzle-image-item-label-info--type-red {
    background-color: $black;
    border: 2px solid $black;
  }

  .nozzle-image {

    background: $secondary6;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    position: relative;
    pointer-events: none;

    .nozzle-image-item {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      background: $secondary1;
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      color: $secondary1;
    }

    .nozzle-image-item--marker {
      background: transparent;
    }

    .nozzle-image-item--type-placeholder_active {
      background: $black;
      border: 1px solid $black;
      color: $black;
    }

    .nozzle-image-item--type-plug {
      background: transparent;
      border: 1px solid $secondary1;
      color: $secondary1;
    }

    .nozzle-image-item--type-green {
      background: $white;
      border: 1px solid $black;
      color: $black;
    }

    .nozzle-image-item--type-red {
      background: $black;
      border: 1px solid $black;
      color: $white;
    }

    .nozzle-image-item-pc {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: bold;
      letter-spacing: -2px;
      transform: translateX(-50%) translateY(-50%);
      text-align: right;
      width: 100%;
      padding-right: 1px;
      color: $black;
    }
  }
}

.nozzle-image-label-size--large {
  .nozzle-image-label-container {
    min-width: 400px;
  }
  .nozzle-image-item-label-title {
    font-size: 20px;
  }
  .nozzle-image-item-label-info {
    font-size: 18px;
    width: 140px;
    padding: 6px;
  }

  .nozzle-image-item-label--top {
    margin-bottom: 44px;
  }

  .nozzle-image-item-label--bottom {
    margin-top: 44px;
  }
}
