@import "./../../../constants";

.initial-settings-container{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(107,107,107,0.4);
  margin-top: -71px;

  @media #{$mobile-max} {
    margin-top: -64px;
  }
  
  .initial-settings-popup-container{
    position: relative;
    width: 100%;
    height: 100%;

    .initial-settings-popup{
      width: calc(100% - 20px);
      max-width: 400px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $secondary3;

      .initial-settings-popup-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        margin: 20px;
      }

      .initial-settings-popup-text{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin: 40px 20px 40px 20px;
        text-align: center;
      }

      .initial-settings-popup-setting {
        min-height: 70px;
        color: $black;
        padding: 10px;
        font-size: $fontsize1;
        display: flex;
        align-items: center;
      }

      .initial-settings-popup-setting-lables{
        flex: 1;
        padding-left: 5px;
        padding-right: 5px;
      }
      .initial-settings-popup-setting-lables:first-of-type{
        text-align: right;
      }

      .initial-settings-popup-setting-line1 {
        font-size: $fontsize1;
        color: $black;
      }

      .initial-settings-popup-setting-line2 {
        font-size: 14px;
        color: $black;
      }

      .initial-settings-popup-setting-toggle{
        margin-left: 15px;
        margin-right: 15px;
      }

      .initial-settings-popup-button{
        font-size: 16px;
        background-color: $primary1;
        height: 50px;
        margin: 20px 10px 20px 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  a:link,
  a:visited{
    color: $black;
    text-decoration: none;
  }

  a:active,
  a:hover{
    color: $black;
    text-decoration: none;
  }
}
