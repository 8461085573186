@import "./../../../constants";

.config-modal{
  cursor: pointer;
}

.angle-modal{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}

.angle-selector-row {

}

.angle-selector-row-value {

}

.angle-selector-close{
  font-size: 26px;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: -3px;
  cursor: pointer;
  color: $secondary1;
}
/*
.angle-selector-ellipsis {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
}
*/

.angle-selector-modal {
  padding: 0px;
}
.angle-selector-modal-row {
  background: $secondary3;
  color: $secondary2;
  text-align: center;
  font-size: $fontsize1;
  border: 1px solid $secondary2;
  padding: 15px;
}

.angle-selector-content{
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -20px;

  > * {
    font-size: 16px !important;
  }
}

.angle-selector-heading {
  font-size: $fontsize2;
  text-align: center;
  color: $secondary1;
  margin-top: 20px;
}

.angle-selector-nozzle-image-container{
  position: relative;
  .angle-selector-nozzle-image{
    position: absolute;
    left:50%;
    margin-left: 10px;
    bottom:50px;
    transform: translateX(-50%);
    width: 40%;
    max-width: 160px;
  }
}

.angle-selector-button-container{
  width: 100%;
  padding: 0px 10px 20px 10px;
}
