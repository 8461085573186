@import "./../../../constants";

.properties {
   background-color: $secondary5;
   padding-bottom: 20px;

  .properties-unit-item-header {
    height: 70px;
    background: $secondary5;
    color: $black;
    padding: 12px 15px;
    font-size: $fontsize1;
    border-bottom: 1px solid $white;
    cursor: pointer;
    position: relative;
  }
  .properties-unit-item-header:nth-child(2) {
    border-top: 0px;
  }

  .properties-unit-item-header-name {
    font-weight: bold;
  }

  .properties-unit-item-header-pressure {
    display: inline-block;
    width: 100px;
    margin-right: 15px;
    white-space: nowrap;
  }

  .properties-unit-item-header-flowrate {
    display: inline-block;
  }

  .properties-unit-item-header-delete-container{
    position: absolute;
    right: 15px;
    height: 40px;
    width: 40px;
    top: 15px;

    .properties-unit-item-header-delete {
      height: 40px;
      width: 40px;
      color: $secondary2;
    }
  }

  .properties-unit-item-properties {
    background: $white;
  }
  .properties-unit-item-properties-item {
    min-height: 45px;
    border-bottom: 2px solid $secondary5;
    color: $secondary2;
    padding: 13px;
    font-size: $fontsize1;
  }
  .properties-unit-item-properties-item:last-child {
    border-bottom: 0px;
  }

  .properties-unit-item-properties-label {
    display: inline-block;
    width: 222px;
    color: black;
    font-weight: bold;
    font-size: $fontsize1;
  }
  .properties-unit-item-properties-input {
    display: inline-block;
  }

  .properties-unit-item-properties-input-name {
    width: calc(100% - 222px);

    input {
      background: transparent;
      border: 0px;
      width: 100%;
    }
  }

  .properties-unit-inner{
    &.new-item{
      display: flex;
      padding: 20px 15px;
      cursor: pointer;
      background: $white;
      font-size: $fontsize1;
      border-top: 1px solid $white;
    }
    &.new-item:hover{
      background: $secondary4;
    }
  }

  .properties-add-new-unit {
    height: 70px;
    background: $secondary5;
    color: $black;
    padding: 23px 15px;
    font-size: $fontsize1;
    color: $secondary2;
    border-top: 1px solid $white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .properties-add-unit-icon{
    margin-top: -2px;
    color: $secondary1;
  }
  .properties-add-unit-lable{
    margin-left: 4px;
    color: $secondary1;
  }

  .properties-setting {
    min-height: 70px;
    background: $secondary5;
    color: $black;
    padding: 10px;
    font-size: $fontsize1;
    color: $secondary2;
    border-bottom: 1px solid $white;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .properties-setting-lables{
    flex: 0,1;
  }

  .properties-setting-line1 {
    font-size: $fontsize2;
    color: $black;
  }

  .properties-setting-line2 {
    font-size: $fontsize1;
    color: $black;
  }

  .properties-setting-toggle-mobile{
    display: block;
  }
  .properties-setting-toggle-desktop{
    display: none;
  }

  .properties-setting-toggle{
    margin-left: 20px;
    margin-right: 20px;
  }

  .properties-toggle {
  }

  .properties-unit-properties{
    height: 0px;
    overflow: hidden;

    &.active{
      height: 100%;
    }

    .properties-unit-button-container{
      border-top: 2px solid $secondary5;
      border-bottom: 2px solid $secondary5;
      background-color: $white;
    }
  }

  .properties-unit-image {
    display: none; //mobile
  }

  .properties-dropdown-container{

    width: 100%;
    height: 50px;

    .Dropdown-root {
      color: $black;
      font-size: $fontsize2;
      cursor: pointer;
      padding-top: 12px;
      position: relative;
    }
    .Dropdown-control {
        position: relative;
        overflow: hidden;
        background-color: $secondary5;
        border-radius: 2px;
        box-sizing: border-box;
        cursor: default;
        outline: none;
        padding: 0px;
        transition: all 200ms ease;
    }
    .Dropdown-control:hover {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
    .Dropdown-arrow {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: ' ';
        display: block;
        height: 0;
        margin-top: -ceil(2.5);
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 0
    }
    .is-open .Dropdown-arrow {
        border-color: transparent transparent #999;
        border-width: 0 5px 5px;
    }
    .Dropdown-menu {
        background-color: $secondary3;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        //max-height: 280px;
        overflow-y: scroll;
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 200px;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
    }

    .Dropdown-menu::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .Dropdown-menu::-webkit-scrollbar:vertical {
        width: 12px;
    }

    .Dropdown-menu::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    .Dropdown-menu::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 10px;
        border: 2px solid $secondary4;
    }

    .Dropdown-menu::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $secondary4;
    }

    .Dropdown-menu .Dropdown-group>.Dropdown-title {
        padding: 8px 10px;
        color: rgba(51, 51, 51, 1.2);
        font-weight: bold;
        text-transform: capitalize;
        border-bottom: 1px solid $secondary6;
    }
    .Dropdown-option {
        box-sizing: border-box;
        color: rgba(51, 51, 51, 0.8);
        cursor: pointer;
        display: block;
        padding: 8px 10px;
    }
    .Dropdown-option:last-child {
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .Dropdown-option:hover {
        background-color: $secondary4;
        color: #333;
    }
    .Dropdown-option.is-selected {
        background-color: #f2f9fc;
        color: #333;
    }
    .Dropdown-noresults {
        box-sizing: border-box;
        color: #ccc;
        cursor: default;
        display: block;
        padding: 8px 10px;
    }
  }
}

@media #{$mobile-min} {

  .properties{

    .properties-setting-lables{
      flex: 1;
    }

    .properties-dropdown-container{
      .Dropdown-control{
        background-color: $secondary4;
      }
    }

    .properties-setting-toggle-mobile{
      display: none;
    }
    .properties-setting-toggle-desktop{
      display: block;
    }

    .properties-unit-image {
      display: block; //desktop
      width: 100px;
      min-width: 100px;
      height: 100px;
      flex: 0;
      margin-right: 30px;
    }

    .properties-units-container{
      padding: 15px 0px 15px 0px;
    }

    .properties-unit-item-header{

      position: absolute;
      top: 15px;
      left: 15px;
      background: $secondary4;
      border: none;

      .properties-unit-item-header-name,
      .properties-unit-item-header-pressure,
      .properties-unit-item-header-flowrate{
        display: none;
      }

      .properties-unit-item-header-delete-container {
        position: absolute;
        top: -15px;
        left: -15px;
        width: 26px;
        height: 26px;
        background-color: $secondary3;

        .properties-unit-item-header-delete {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 20px;
          width: 20px;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .properties-unit-item-header-delete-container:hover{
        background-color: $secondary5;
      }

      .properties-unit-item-header-delete{
        top:0px;
        left:0px;
        color: $secondary1;
      }
    }

    .properties-unit-container{
      margin: 15px 15px 20px 15px;
      display: inline-block;
      width: 560px;
      height: 330px;
    }

    .properties-setting-container{
      margin: 30px 15px;
      display: inline-block;
      width: 560px;
      height: auto;
    }

    .properties-setting,
    .properties-unit-inner{
      padding: 30px;
      background: $secondary4;
      border: 1px solid $secondary1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &.new-item{
        display: flex;
        padding: 30px;
        background: $secondary5;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
      }
      &.new-item:hover{
        background: $secondary5;
      }
    }

    .properties-setting{
      justify-content: space-between;
      padding: 40px;
    }

    .properties-unit-properties{
      flex:1;
      height: 100%;

      .properties-unit-button-container{
        display: none;
      }
    }

    .properties-unit-item-properties{
      background: $secondary4;
    }

    .properties-unit-container{
      vertical-align: middle;
      &.new-item {
        height: auto;
      }
    }

    .properties-add-unit-icon{
      display: inline-block;
      svg {
        width: 70px;
        height: 70px;
      }
      color: $secondary1;
      padding: 30px;
      margin-left: 15px;
      background: $secondary4;
      border: 1px solid $secondary1;
    }
    .properties-add-unit-icon:hover{
      background: $secondary7;
    }

    .properties-add-unit-lable {
      color: $black;
      font-weight: bold;
      margin-left: 24px;
    }


  }

}
