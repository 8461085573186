@import "./../../../constants";

loading-indicator{
  text-align: center;
  padding: 24px;
}

@each $name, $color in $colors {
  .loading-indicator-#{$name} .line-scale > div {
    background-color: $color !important;
  }
}
