@import "./../../../constants";

.config-modal{
  cursor: pointer;
}

.hose-configuration-table-modal-item-container {
  display: block;
}

.hose-configuration-table-row-spacer{
  height: 2px;
  margin: 0px 15px 0px 15px;
  background-color: $secondary5;
}

.hose-configuration-table-modal-item {
  font-size: $fontsize2;
  text-align: center;
  cursor: pointer;
  display: flex;
  padding: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected{
    font-weight: bold;
  }
}

.hose-configuration-table-modal-item-custom {
  height: 60px;
  padding: 15px 5px 5px 5px;
  font-size: $fontsize1;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hose-configuration-table-modal-item-custom:hover,
.hose-configuration-table-modal-item:hover{
  background-color: $secondary6;
}

.hose-configuration-table-modal-custom-type-input {
  margin-bottom: 10px;
  background: transparent;
  border: 0px;
  text-align: center;
  font-size: $fontsize3;
}
