@import "./../../../constants";

.ios-statusbar{
  height: env(safe-area-inset-top);
  width: 100%;
  background-color: $primary1;
  position: fixed;
  top:0px;
  z-index: 100;
}

.header{
  height: 64px;
  width: 100%;
  background-color: $primary1;
  overflow: hidden;
  top: 0;
  left: 0;
  border-bottom: 1px solid $primary1;
  z-index: 10;
  padding: 15px;
  display: flex;
  z-index: 100;
  position: fixed;

  &.ios-statusbar{
    top: env(safe-area-inset-top);
  }

  &.mobile{
    background-color: $primary1;
    height: 64px !important;
  }
  @media #{$mobile-max} {
    background-color: $primary1;
    height: 64px !important;
  }

  .header-logo{
    display:inline-block;
  }

  .header-logo-img{
    margin: auto 50px auto 0px;
    height: 37px;
    float:left;

    &.mobile{
      display: none;
    }
    @media #{$mobile-max} {
      display: none;
    }
  }

  .header-title{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    font-size: $fontsize2;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-right: 15px;
    padding: 0px 55px 0px 40px;
  }

  .header-menu {
    height: 37px;
    position: relative;
    width: 100%;
    display: flex;
  }

  .header-menu-item,
  .header-menu-item:active,
  .header-menu-item:link,
  .header-menu-item:hover,
  .header-menu-item:visited,
  .header-menu-item:focus {
    display: flex;
    align-items: center;
    margin: 0px 5px;
    height: 100%;
    border: 1px solid $secondary1;
    color: $secondary1;
    padding: 0px 6px;
    flex-grow: 1;
  }

  .header-menu-item-active
  .header-menu-item-active:active,
  .header-menu-item-active:link,
  .header-menu-item-active:hover,
  .header-menu-item-active:visited,
  .header-menu-item-active:focus{
    position: relative;
    color: $white;
    background: $secondary1;
  }

}


@media #{$mobile-min} {
  .header {
    height: 71px;
    background: $secondary3;
    padding-top: 17px;
    border: 0px;
  }
  .header-title {
    display: none;
  }
}

@media #{$mobile-max} {
  .header-logo {
    display: none;
  }
  .header-menu {
    display: none !important;
  }
  .header {
    position: fixed;
  }
}
