@import "./../../../constants";

.disclaimer-container{
  background-color: $white;
}

.disclaimer{
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 150px;
  max-width: 1000px;

  h1,h2,h3,h4{
    color: $black;
  }

  h3,h4{
    padding-top: 20px;
    padding-bottom: 5px;

    &.small-top-padding{
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }

  a:link,
  a:visited{
    color: $secondary1;
    text-decoration: none;
  }

  a:active,
  a:hover{
    color: $secondary1;
    text-decoration: underline;
  }
}
