@import "./../../../constants";

.product-wizard-item-collection {
  margin: 10px;

  .product-wizard-item {
    border: 1px solid $secondary1 !important;
    margin-top: -1px;
  }
}

.product-wizard-item {
  width: 100%;
  padding: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $secondary2;

  &.product-wizard-item-type--clickable {
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: $secondary6;
      color: $secondary2;
    }
  }

  &.product-wizard-item--active {
    background-color: $secondary1;
    color: $white;
  }

  &.product-wizard-item--disabled {
    background-color: $secondary5 !important;
    color: $secondary9 !important;
    pointer-events: none;
  }

  &.product-wizard-item-type--border {
    border-bottom: 1px solid $secondary1 !important;
  }

  .product-wizard-item-image {
    margin-right: 8px;
    min-width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 60px;
    }
  }

  .product-wizard-item-label {
    flex: 1;
  }
}

.product-wizard-item-type--simple {

  align-items: center;
  justify-content: center;

  &.product-wizard-item-type--clickable {
    font-weight: normal;
  }

  .product-wizard-masterjet-label-ellipsis {
    display: none;
  }

  .product-wizard-item-label {
    flex: unset;
  }
}

.product-wizard-item-type--hint {
  background-color: $secondary9;
  color: $secondary8;
  border: 10px solid $secondary3;
}

.product-wizard-modal-label {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}
