@import "./../../../constants";

.modal-selector-scroller{
  margin: 0px 0px 64px 0px;
  overflow-y: auto;
  min-width: unset;
  min-width: 255px;
  width: 95%;
  max-width: 500px;
  max-height: calc(95% - 64px);

  &.modal-selector-ios-status-bar{
    top: calc(50% + 32px + 11px);
    max-height: calc(95% - 64px - env(safe-area-inset-bottom));
  }
}


@media #{$mobile-min} {
  .modal-selector-scroller{
    max-height: 600px;
  }
}
