@import "./../../../constants";

.product-wizard-product-container {

  &.border {
    border-bottom: 1px solid $secondary1 !important;
  }

  .product-wizard-product-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    color: $secondary2;
    cursor: pointer;

    &.clickable {
      cursor: pointer;
      font-weight: bold;

      &:hover {
        background-color: $secondary5;
      }
    }

    .product-wizard-product-item-container {
      padding: 12px 16px;
      display: flex;
      width: 100%;
    }

    .product-wizard-product-item-image {
      display: flex;
      align-items: center;
      margin-right: 16px;

      img {
        width: 50px;
        max-height: 50px;
      }

      .product-wizard-product-item-image-default {
        width: 50px;
        height: 50px;
        border: 1px $secondary9 dashed;
      }
    }

    .product-wizard-product-item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .product-wizard-product-item-label {
    }

    .product-wizard-product-item-attributes {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      font-weight: normal;
    }

    .product-wizard-product-item-attribute {
      padding-right: 16px;
      padding-bottom: 8px;
    }
  }

  .product-wizard-product-item-collapse-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 110px;
    width: 80px;
  }

  .product-wizard-product-item-collapse-attributes-container {
    background-color: $secondary6;
    padding: 12px 24px;

    .product-wizard-product-item-collapse-attribute {
      padding: 4px;
      display: flex;

      .product-wizard-product-item-collapse-attribute-label {
        flex: 1;
        margin-top: 1px;
        font-size: 14px;
        font-weight: bold;
        color: $secondary2;
        margin-right: 32px;
      }

      .product-wizard-product-item-collapse-attribute-value {
        flex: 1;
        color: $secondary2;
      }
    }
  }
}
