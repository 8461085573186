@import "./../../../constants";

.product-wizard-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  padding-top: 20px;
  background-color: $secondary3;

  .product-wizard-navigation-item {
    flex: 1
  }

  .product-wizard-navigation-item > div {
    height: 50px;
    background-color: $secondary2;
    color: $white;

    &:hover {
      background-color: $secondary1;
    }
  }
}

.product-wizard-navigation--type-full {
  padding: 0px;
  .product-wizard-navigation-item > div {
    height: 60px;
  }
}
