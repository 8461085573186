@import "./../../../constants";

.data-unit-textfield-editable {
  cursor: text;
}
.data-unit-textfield-not-editable {
  cursor: default;
}

.data-unit-textfield {

  display: flex;
  flex-direction: row;
  min-width:100px;

  width: 100%;

  .data-unit-textfield-label--labelOnly {
    margin-left: 10px;
  }

  input {
    padding: 0px;
  }

  input::-ms-clear {
    display: none;
  }

  .dynamic-number-container{
    margin-right: 3px;
    text-align: right;

  }

  .data-unit-textfield-ce {
    padding-left: 10px;
    padding-right: 4px;

  }
  .data-unit-textfield-ce-right {
    text-align: right;
  }
  .data-unit-textfield-ce-right-m-left-d{
    text-align: right;

    @media #{$mobile-min} {
      text-align: left;
    }
  }
  .data-unit-textfield-ce-center {
    text-align: center;
  }
  .data-unit-textfield-ce-center-auto {
    text-align: center;
  }
  .data-unit-textfield-ce-left {
    text-align: left;
  }
  .data-unit-textfield-ce-editable {
  }

  .data-unit-textfield-ce-editable:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
  }

  .data-unit-textfield-ce br {
    display: none;

  }
  .data-unit-textfield-ce * {
    display: inline;
    white-space: nowrap;
  }

  .data-unit-textfield-label{

  }
}

.data-unit-textfield-nostyle{
  width: auto;
  min-width: auto;

  .data-unit-textfield-ce-nostyle {
    padding-left: 0px;
  }
}

.data-unit-textfield input[type="number"]::-webkit-outer-spin-button,
.data-unit-textfield input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.data-unit-textfield input[type="number"] {
  -moz-appearance: textfield;
}
.data-unit-textfield-tmp-input,
.data-unit-textfield input{
  text-align: center;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
}

.data-unit-textfield-tmp-input {
  visibility: hidden;
  white-space: pre;
}

.data-unit-textfield-right {
  justify-content: flex-end;

  input,
  .data-unit-textfield-ce{
    text-align: right;
  }
  .data-unit-textfield-label {
    text-align: right;
  }
}

.data-unit-textfield-right-m-left-d{
  justify-content: flex-end;

  input,
  .data-unit-textfield-ce{
    text-align: right;
  }
  .data-unit-textfield-label {
    text-align: right;
  }

  @media #{$mobile-min} {
    justify-content: flex-start;

    input,
    .data-unit-textfield-ce{
      text-align: left;
    }
    .data-unit-textfield-label {
      text-align: left;
    }
  }
}

.data-unit-textfield-center {
  justify-content: center;

  input,
  .data-unit-textfield-ce{
    text-align: right;
  }
  .data-unit-textfield-label {
    text-align: left;
  }
  > div {
    //flex: 1;
  }
}
.data-unit-textfield-center-auto {
  justify-content: center;

  input,
  .data-unit-textfield-ce{
    text-align: right;
  }
  .data-unit-textfield-label {
    text-align: left;
    margin-right: 10px;
  }
  > div {
    flex: none;
  }
}
.data-unit-textfield-left {
  justify-content: flex-start;

  input,
  .data-unit-textfield-ce{
    text-align: left;
  }
  .data-unit-textfield-label {
    text-align: left;
  }
}
