@import "./../../../constants";

.section-header {
  background: $secondary2;
  height: 48px;
  color: $white;
  padding: 14px 15px;
  font-size: $fontsize1;
  font-weight: bold;
  text-transform: uppercase;
}
