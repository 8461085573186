@import "./../../../constants";

.contact {
   position: absolute;
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   min-height: 400px;
}

.contact .section-header {
   flex: 0;
}

.contact-height-wrapper {
   height: 100%;
   position: relative;
}

.contact-height-wrapper-small {
   height: 60%;
}

.contact-main-buttons {
   margin-top: 87px;
   text-align: center;
}

.contact-button-gps {
   border-bottom: 1px solid $black;
}

.contact-main-button {

}

.contact-background-map {
   top: 0px;
   height: 100%;
   width: 100%;
   position: absolute;
   filter: grayscale(80%);

   .contact-map-osm-attribution {
      a {
         color: $secondary2;
         position: relative;
         z-index: 10;
         display: inline-block;
      }
      opacity: 0.7;
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 2px;
      background: $white;
      color: $secondary2;
      font-size: 11px;
      font-family: Arial;
   }

   > div:first-child {
      height: 100%;

      > .ol-react-map {
         height: 100%;
      }
   }
}

.contact-background-map-small {
   height: calc(60% + 90px); // 40% is bottom overlay height + 90px for the transparent elements
}

.contact-overlay-top {
   top: 15px;
   height: calc(100% - 15px);
   width: 100%;
   position: absolute;
   z-index:1;
}

.contact-overlay-bottom-container {
  width:100%;
  height: 100%;
  position: absolute;
  z-index:2;
  pointer-events: none;

  .contact-overlay-bottom-spacer {
    height: 60%;
    min-height: 200px;
  }

  .contact-overlay-bottom {
     min-height: 40%;
     z-index:2;
     width:100%;
     display: flex;
     flex-direction: column;
     pointer-events: auto;
  }
}

.contact-action-button-container {
   height: 50px;
   padding: 10px;
}

.contact-suggestions {
   background-color: $secondary5;
   flex: 1;
}

.contact-suggestion {
   padding: 10px 20px;
   background-color: $secondary5;
   border-top: 1px solid $secondary1;
}
.contact-suggestion a,
.contact-suggestion a:link,
.contact-suggestion a:visited,
.contact-suggestion a:hover {
   color: $black;
}
.contact-suggestion:first-child {
   padding-top: 20px;
   border-top: 0px;
}
.contact-suggestion:last-child {
   padding-bottom: 20px;
}

.contact-suggestion-left {
   display: inline-block;
   width: calc(50% - 10px);
   margin-right: 10px;
   margin-bottom: 10px;
}
.contact-suggestion-right {
   display: inline-block;
   width: calc(50% - 10px);
   margin-left: 10px;
   margin-bottom: 10px;
}

.contact-suggestion-name{
   font-weight: bold;
}
.contact-suggestion-country{
   font-weight: bold;
}
.contact-suggestion-street{
   margin-top: 5px;
}
.contact-suggestion-place{
}

.contact-suggestion-person{
   font-weight: bold;
   margin: 10px 0px;
}


.contact-suggestion{
  cursor: pointer;
}
.contact-suggestion:hover{
  background: $secondary3;

  &.contact-suggestion-selected{
    background: $secondary1;
  }
}

.contact-suggestions-triangle-container {
   text-align: center;
   padding-top: 20px;
   font-size: 0px;

   .contact-suggestions-triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 20px 20px;
      border-color: transparent transparent $secondary5 transparent;
   }

   &.contact-suggestions-triangle-container-active {
      .contact-suggestions-triangle {
         border-color: transparent transparent $secondary1 transparent;
      }
   }
}

.contact-suggestion-selected {
   background: $secondary1;
   color: $white;

   a,
   a:link,
   a:visited,
   a:hover {
      color: $white;
   }
}

.contact-back-button {
   background: $secondary5;
   padding: 10px;
   display: inline-block;
   margin: 15px 0px 0px 15px;
   cursor: pointer;
   >svg {
      width: 30px;
      height: 30px;
   }
}

.contact-back-button:hover{
  background: $secondary3;
}

.contact-search-wrapper {
   text-align: center;

   background: $secondary5;
   display: flex;
   height: 50px;
   padding: 5px;
   max-width: 300px;
   margin: 37px auto 0px auto;

   svg {
      width: 20px;
      height: 20px;
      margin: 10px;
   }
}

.contact-search-input {
   border: 0;
   padding: 5px;
   flex: 1;
   margin: 10px 10px 10px 0px;
   background: transparent;
   border-bottom: 1px solid $secondary2;
}
