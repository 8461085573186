@import "./../../../constants";

.nozzle-tab-info {
  flex-basis: 0;
  flex-grow: 1;
  color: $black;
  margin-left: 10px;
  display: none;
}

.nozzle-tab-info-mobile {
  color: $black;
  background-color: $white;
  padding-left: 16px;
  padding-top: 10px;
  display: block;
  font-size: 16px;
}

.nozzle-diameter-modal {
  margin: 0px 0px 64px 0px;
  overflow-y: auto;
  min-width: unset;
  min-width: 255px;
  width: 95%;
  max-width: 500px;
  max-height: calc(95% - 64px);
}

.nozzle-diameter-modal-container{
  display: block;
  padding: 4px;
}

.nozzle-diameter-modal-item-noevents,
.nozzle-diameter-modal-item-subline,
.nozzle-diameter-modal-item {
  font-size: $fontsize2;
  text-align: center;
  cursor: pointer;
  display: block;
  padding: 15px;
  align-items: center;
}

.nozzle-diameter-modal-item:hover{
  background-color: $secondary5;
}

.nozzle-diameter-modal-item-noevents{
  pointer-events: none;
}

.nozzle-diameter-modal-item-subline{
  color: $secondary1;
}

.nozzle-diameter-modal-item-cancel {
  font-weight: bold;
  border-top: 1px solid $secondary6;
}

.nozzle-diameter-valid-nozzle-ellipsis {
  width: 21px;
  height: 21px;
  margin-left: -2px;
}

.nozzle-diameter-valid-nozzle-item {
  display: flex;

  > div{
    min-width: 0px;
  }
}

.nozzle-diameter-valid-nozzle-dutf-container{
  display: flex;
  justify-content: flex-end;
}

.nozzle-diameter-valid-nozzle-dutf {
  display: flex;
}

@media #{$mobile-min} {

  .nozzle-tab-info-mobile {
    display: none;
  }
  .nozzle-tab-info{
    display: inline-block;;
  }

  .nozzle-diameter-valid-nozzle-ellipsis {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-left: -8px;
  }
}
