@import "./../../../constants";

.calculation-result-triangle-container {
  background: $white;
  text-align: center;
  padding-top: 20px;
  font-size: 0px;

  .calculation-result-triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
  }

  .calculation-result-triangle-valid {
    border-color: transparent transparent $secondary2 transparent;
  }

  .calculation-result-triangle-warning {
    border-color: transparent transparent $cwarning transparent;
  }

  .calculation-result-triangle-error {
    border-color: transparent transparent $cerror transparent;
  }
}

.calculation-result-header-container{
  display: none;
}

.calculation-result-values-item:last-of-type {
  padding-bottom: 10px;
}

.calculation-result-values-item {
  min-height: 40px;
  padding-top: 10px;
  display: flex;

  .calculation-result-values-item-value {
    color: $secondary3;
    display: inline-block;
    font-size: $fontsize1;
    width: 45%;
    margin-right: 10px;
    text-align: center;
    flex:1;
    max-width: 50%;
  }
  .calculation-result-values-item-label {
    color: $white;
    display: inline-block;
    font-size: $fontsize1;
    font-weight: bold;
    flex:1;
    max-width: 50%;
    overflow-wrap: break-word;
  }
}

.calculation-result-values-item-valid {
  background: $secondary2;
}

.calculation-result-values-item-warning {
  background: $cwarning;
}

.calculation-result-values-item-error {
  background: $cerror;
}

.calculation-result-warnings-count {
  background: $white;
  color: $black;
  position: relative;
  height: 40px;
  padding: 10px 10px 0px 50px;
  font-size: $fontsize1;

  .calculation-result-warnings-count-icon {
    position: absolute;
    left: 15px;
    top: 8px;
    height: 25px;
    width: 25px;
  }
}


.calculation-result-warnings-item {

  cursor: pointer;
  position: relative;

  .calculation-result-warnings-item-title{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .calculation-result-warnings-item-title-left{
      display: flex;
      font-weight: bold;
      align-items: center;
    }
  }

  .calculation-result-warnings-item-icon {
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding-left: 5px;
  }


  padding: 7px 10px 0px 10px;

  color: $black;
  font-size: $fontsize1;
}

.calculation-result-warnings-item-info {
  background: $secondary2;
  color: white;
  font-weight: normal;
}

.calculation-result-warnings-item-warning {
  background: $cwarning;
}

.calculation-result-warnings-item-error {
  background: $cerror;
}

.calculation-result-warnings-item-infotext {

  display: none;
  padding: 7px;
  padding-left: 40px;
  &.active{
    display: block;
  }
}

.calculation-result-warnings-item-moreinfo {
  text-decoration: underline;
  min-width: 80px;
}

.calculation-result-warnings-item-label {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.calculation-result-container{
  display: flex;
  flex-direction: column;
}

@media #{$mobile-min} {

  .calculation-result-values{
    padding-right: 0px;
  }

  .calculation-result-triangle-container{
    display: none;
  }
  .calculation-result-header-container{
    display: block;
  }
  .calculation-result-container{
    flex-direction: row;
  }

  .calculation-result-values-item{
    margin: 30px 0px 30px 15px;
    border: 1px solid $secondary6;
    background-color: $secondary7;
    font-weight: bold;
    padding: 5px;
    padding-left: 0px;
    min-width: 480px;
    display: flex;
    min-height: 50px;
    justify-content: space-between;
    border-bottom: 4px solid $secondary1;

    .calculation-result-values-item-value{
      color: $black;
      font-size: $fontsize3;
      font-weight: bold;
      width: auto;
    }
    .calculation-result-values-item-label{
      margin-top: 5px;
      color: $secondary1;
      font-size: $fontsize2;
      width: auto;
    }
  }

  .calculation-result-values-item-valid {
    border-bottom: 4px solid $secondary1;
  }

  .calculation-result-values-item-warning {
    border-bottom: 4px solid $cwarning;
    background-color: $cwarning_low;
  }

  .calculation-result-values-item-error {
    border-bottom: 4px solid $cerror;
    background-color: $cerror_low;
  }

  .calculation-result-warnings{
    margin: 15px;
  }

  .calculation-result-warnings-count{
    display: none;
  }

  .calculation-result-warnings-item{
    border: 1px solid $secondary6;
    background-color: $secondary7;
    padding: 0px;
    padding-bottom: 10px;
    cursor: auto;
    max-width: 480px;
    min-width: 480px;
    display: inline-block;
    vertical-align: top;
    margin: 15px;
    color:black;

    .calculation-result-warnings-item-title{
      border-bottom: 4px solid $secondary1;
      padding: 7px;
    }

    .calculation-result-warnings-item-moreinfo{
      display: none;
    }

    .calculation-result-warnings-item-infotext{
      display: block;
    }
  }

  .calculation-result-warnings-item-warning {
    background-color: $cwarning_low;
    .calculation-result-warnings-item-title{
      border-bottom: 4px solid $cwarning;
    }
  }

  .calculation-result-warnings-item-error {
    background-color: $cerror_low;
    .calculation-result-warnings-item-title{
      border-bottom: 4px solid $cerror;
    }
  }

  .calculation-result-warnings-item-label{
    font-size: $fontsize2;
  }

  .calculation-result-warnings-item-infotext {
    font-size: $fontsize2;
  }
}
